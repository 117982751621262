<template>
  <div>
    <div v-if="!showDetail">
      <div class="title">过户管理</div>

      <div class="block">
        <el-form ref="form" :model="form" size="small">
          <el-row>
            <el-col :span="5">
              <el-form-item
                label="过户单号："
                label-width="100px"
                prop="transferOrderNumber"
              >
                <el-input
                  v-model="form.transferOrderNumber"
                  style="width: 160px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item
                label="卖方名称："
                label-width="100px"
                prop="sellerName"
              >
                <el-input
                  v-model="form.sellerName"
                  style="width: 160px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="开单日期：" label-width="100px" prop="date">
                <el-date-picker
                  v-model="form.date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item>
                <div style="display: flex">
                  <div class="btn search" @click="search">搜索</div>
                  <div class="btn reset" @click="reset">重置</div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="export-btn" @click="exportFile">导出Excel</div>

      <div class="table">
        <el-table
          :data="tableData"
          :header-cell-style="{
            'text-align': 'center',
            'font-weight': 'normal',
            'font-size': '12px',
            color: '#333',
          }"
          :cell-style="{
            'text-align': 'center',
            'font-weight': 'normal',
            'font-size': '12px',
            color: '#333',
          }"
          border
          style="width: 100%"
          size="mini"
        >
          <el-table-column
            v-for="(item, index) in tableHeader"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
          >
            <template slot-scope="scope">
              <div v-if="item.prop !== 'operate'">
                {{ scope.row[item.prop] }}
              </div>
              <div v-else>
                <el-button
                  v-for="(item, index) in scope.row.operate"
                  :key="index"
                  type="text"
                  size="mini"
                  @click="operate(item.node_id, scope.row)"
                  >{{ item.name }}</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="total, prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
          style="margin-top: 10px"
        ></el-pagination>
      </div>
    </div>

    <div v-if="showDetail">
      <div class="title">仓单过户单明细</div>
      <div class="c-title">过户信息</div>
      <div class="info">
        <div class="info-block">
          <div class="form-item bottom">
            <div class="label">过户单号：</div>
            <div class="value">{{ transferOrderNumber }}</div>
          </div>
          <div class="form-item">
            <div class="label">卖家：</div>
            <div class="value">{{ sellerName }}</div>
          </div>
        </div>
        <div class="info-block">
          <div class="form-item bottom">
            <div class="label">买家：</div>
            <div class="value">{{ buyerName }}</div>
          </div>
          <div class="form-item">
            <div class="label">过户仓库：</div>
            <div class="value">{{ warehouseName }}</div>
          </div>
        </div>
      </div>
      <div class="c-title">过户明细</div>
      <el-table :data="warehouseReceipts" border style="width: 100%">
        <el-table-column
          prop="commodityName"
          label="品名/品牌"
          align="center"
          width="560"
        >
          <template slot-scope="scope"
            >{{ scope.row.commodityName + "/" + scope.row.productorName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="billingWeight"
          label="开单重量"
          align="center"
          width="360"
        >
        </el-table-column>
        <el-table-column prop="transferNumber" label="过户件数" align="center">
        </el-table-column>
        <el-table-column prop="transferWeight" label="过户重量" align="center">
        </el-table-column>
      </el-table>

      <div class="back-btn" @click="goback">返回</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const http = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
  },
});
export default {
  data() {
    return {
      form: {
        transferOrderNumber: "",
        sellerName: "",
        date: [],
      },
      tableData: [],
      tableHeader: [],
      showDetail: false,

      total: 0,

      currentPage: 0,
      pageSize: 10,

      buyerName: "",
      sellerName: "",
      transferOrderNumber: "",
      warehouseName: "",
      warehouseReceipts: [],
    };
  },
  created() {
    this.search();
  },
  methods: {
    search() {
      let params = {
        process_id: "00000216", // 流程ID
        // 传参  查询则是查询条件  可传可不传等
        nodeInfo: {
          size: this.pageSize, // 分页页容量（从0开始，-1代表查全部）
          page: this.currentPage, // 分页页码（从0开始）
          transferOrderNumber: this.form.transferOrderNumber, // 过户单号（可不传）
          sellerName: this.form.sellerName, // 买方名称
          beginBillingDate: this.form.date[0] || "", // 开单日期（起）
          endBillingDate: this.form.date[1] || "", // 开单日期（止）
          pageType: "Purchases",
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          this.tableData = res.data.content;
          this.tableHeader = res.data.title;
          this.total = res.data.total;
        })
        .catch((err) => {});
    },
    reset() {
      this.form = {
        transferOrderNumber: "",
        sellerName: "",
        date: [],
      };
      this.search();
    },
    exportFile() {
      let params = {
        process_id: "00000210",
        execution_type: "get", // get 代表读请求 获取数据,  set	代表写请求 编辑等操作
        nextProcessNode: "005", // 列表接口的  button.NodeId_1字段的值
        nodeInfo: {
          size: -1, // 分页页容量（从0开始，-1代表查全部） 可不传
          page: 0, // 分页页码（从0开始）  可不传
          transferOrderNumber: this.form.transferOrderNumber, // 过户单号（可不传）
          sellerName: this.form.sellerName, // 买方名称
          beginBillingDate: this.form.date[0] || "", // 开单日期（起）
          endBillingDate: this.form.date[1] || "", // 开单日期（止）
          pageType: "Purchases",
          exportType: "transferManagement",
        },
      };
      let p1 = new Promise((resolve, reject) => {
        this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
          .then((res) => {
            resolve(res.data.id);
          })
          .catch((err) => {});
      });
      p1.then((res) => {
        http
          .get("/file/getFilePath?fileId=" + res)
          .then((result) => {
            const { data } = result.data;
            let url = data["common_file@path"];
            window.open(`/downfile${url}`, "_blank");
          })
          .catch((err) => {});
      });
    },
    operate(id, data) {
      if (id == "001") {
        this.showDetail = true;
        // 查看按钮点击事件
        this.searchDetail(data.transferOrderId);
      } else if (id == "002") {
        // 打印事件
        this.print();
      }
    },
    searchDetail(transferOrderId) {
      let params = {
        process_id: "00000210",
        execution_type: "get", // get 代表读请求 获取数据,  set	代表写请求 编辑等操作
        nextProcessNode: "001", // 上面接口的  operate.node_id字段的值
        nodeInfo: {
          transferOrderId: transferOrderId, // 过户单ID
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          const { data } = res;
          const {
            buyerName,
            sellerName,
            transferOrderNumber,
            warehouseName,
            warehouseReceipts,
          } = data;
          this.buyerName = buyerName;
          this.sellerName = sellerName;
          this.transferOrderNumber = transferOrderNumber;
          this.warehouseName = warehouseName;
          this.warehouseReceipts = warehouseReceipts;
        })
        .catch((err) => {});
    },
    goback() {
      this.showDetail = false;
    },
    handleCurrentChange(page) {
      this.currentPage = page - 1;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  border-bottom: 3px solid #1d74e0;
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}
.block {
  border: #e5e5e5 1px solid;
  padding: 10px 30px 0 30px;
  margin-top: 5px;
  .btn {
    background: #2d5171;
    color: #ffffff;
    font-size: 12px;
    margin-left: 10px;
    height: 30px;
    line-height: 30px;
    width: 80px;
    margin-top: 2px;
    text-align: center;
    cursor: pointer;
  }
}
.export-btn {
  background: #2d5171;
  color: #ffffff;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  width: 80px;
  margin-top: 5px;
  text-align: center;
  cursor: pointer;
}
.table {
  margin-top: 10px;
}
.c-title {
  color: #717071;
  font-size: 14px;
  height: 35px;
  margin: 10px auto;
  line-height: 35px;
  border-bottom: 1px dashed #e3e3e3;
}
.info {
  display: flex;
  padding: 10px 0 10px 100px;
  .info-block {
    flex-basis: 400px;
    .form-item {
      display: flex;
      align-items: center;
      .label {
        width: 100px;
        text-align: right;
        font-size: 14px;
        color: #666666;
      }
      .value {
        color: #666666;
        font-size: 14px;
      }
      &.bottom {
        margin-bottom: 20px;
      }
    }
  }
}
.back-btn {
  background: #2d5171;
  color: #ffffff;
  font-size: 12px;
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  width: 80px;
  margin-top: 2px;
  text-align: center;
  cursor: pointer;
}
</style>
